<template>
  <v-container class="pa-0 ma-0">

    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="text-h6">
        WDS Программные агенты
      </div>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" fixed>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Панель управления
          </v-list-item-title>
          <v-list-item-subtitle v-if="isAuth && !!profileUser">
            <b>{{ profileUser.username }}</b> {{ profileUser.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            Не авторизованны
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list
          dense
          nav>
        <v-list-item
            link
            to="/">

          <v-list-item-title>
            <v-icon>mdi-view-dashboard-outline</v-icon>
            Главная страница
          </v-list-item-title>
        </v-list-item>

        <v-list-item link v-if="isAuth" to="/profile">
          <v-list-item-title>
            <v-icon>mdi-account-box-outline</v-icon>
            Профиль
          </v-list-item-title>
        </v-list-item>

        <v-list-item link v-if="isAuth" to="/user/notification">
          <v-list-item-title>
            <v-icon>mdi-bell-outline</v-icon>
            Уведомления
            <v-badge
                v-if="countNewNotification !== 0"
                color="info"
                :content="countNewNotification"
                inline
            ></v-badge>

          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item
            link
            to="/program-agents">
          <v-list-item-title>
            <v-icon>mdi-robot-excited-outline</v-icon>
            Агенты
          </v-list-item-title>
        </v-list-item>


        <v-list-item link v-if="isAuth" to="/profile/wallet">
          <v-list-item-title>
            <v-icon>mdi-wallet-outline</v-icon>
            Кошелёк
          </v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="isAuth"
            link
            to="/payments/program-agent">
          <v-list-item-title>
            <v-icon>mdi-cash</v-icon>
            Платежи
          </v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="isAuth && isAdmin"
            link
            to="/users-list">
          <v-list-item-title>
            <v-icon>mdi-account-group-outline</v-icon>
            Пользователи
          </v-list-item-title>
        </v-list-item>


        <v-divider></v-divider>

        <v-list-item link v-if="isAuth" to="/logout">
          <v-list-item-title>
            <v-icon>mdi-account-minus-outline</v-icon>
            Выйти
          </v-list-item-title>
        </v-list-item>

        <v-list-item link v-if="!isAuth" :to="'/login'">
          <v-list-item-title>
            <v-icon>mdi-account-plus-outline</v-icon>
            Войти
          </v-list-item-title>
        </v-list-item>

        <!--        <v-list-item link v-if="!isAuth" :to="'/registration'">-->
        <!--          <v-list-item-title>-->
        <!--            <v-icon>mdi-account-multiple-plus-outline</v-icon>-->
        <!--            Регистрация-->
        <!--          </v-list-item-title>-->
        <!--        </v-list-item>-->

        <v-divider></v-divider>

        <v-list-item
            link to="/price-list">
          <v-list-item-title>
            <v-icon>mdi-currency-usd</v-icon>
            Тарифы
          </v-list-item-title>
        </v-list-item>

        <v-list-item
            link to="/project/news">
          <v-list-item-title>
            <v-icon>mdi-newspaper</v-icon>
            Новости проекта
          </v-list-item-title>
        </v-list-item>

        <v-list-item
            link to="/contacts">
          <v-list-item-title>
            <v-icon>mdi-card-account-mail-outline</v-icon>
            Контакты
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import userProfileData from "@/mixins/userProfileData";
import userApi from "@/api/userApi";

export default {
  name: "NavbarView",
  mixins: [userProfileData],
  data() {
    return {
      drawer: true,
      countNewNotification: 0
    }
  },
  computed: {},
  methods: {
    loadCountNewNotification() {
      if (this.isAuth)
        userApi.getCountNewNotification(ok => this.countNewNotification = ok.data.count, error => {
        })
    }
  },
  watch: {
    $route(to, from) {
      this.loadCountNewNotification()
    }
  },
  mounted() {
    this.drawer = this.isAuth;
  }
}
</script>

<style scoped>

</style>
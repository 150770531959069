<template>
  <v-card>
    <v-card-title>
      Процессы
    </v-card-title>
    <v-card-actions class="ma-2" v-if="progressBars && progressBars.length !== 0">
      <v-row>
        <v-col cols="12">
          <v-row v-for="bar in progressBars" :key="bar.name" :class="{'text-grey': !bar.isProcessing}">
            <v-col cols="12" class="pb-0">
              <b>{{ bar.name }}</b> / {{ bar.description }}
            </v-col>
            <v-col cols="12" class="pt-1">
              <v-progress-linear height="10" v-model="bar.progress" max="1"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text v-else>
      Данные отсутствуют
    </v-card-text>
  </v-card>
</template>

<script>
import ProgramAgentTableCard from "@/components/ProgramAgentTableCard.vue";
import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";

export default {
  name: "ProgramAgentProgressBarsCard",
  components: {ProgramAgentTableCard},
  data() {
    return {
      progressBars: [],
      timer: null
    }
  },
  props: {
    agentInfo: {type: Object}
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    updateProgressBar(){
      if(!this.agentInfo.online)
        return
      const pathBar = config.api + "/user/agent/progressbar"
      axios.get(pathBar, {
        params:{
          id: this.agentInfo.id
        },
        headers: api.getHeadersLogin()
      }).then(value => {
        this.progressBars = value.data
      }).catch(reason => {
        this.stopTimer()
      })
    },
    startTimer() {
      if(!this.agentInfo.online)
        return
      this.updateProgressBar()
      this.timer = setInterval(() => {
        try {
          this.updateProgressBar()
        }catch (e) {
          this.stopTimer()
        }
      }, 5000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  }
}
</script>

<style scoped>

</style>
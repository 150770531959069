<template>

  <v-card :loading="loadingHistory">
    <v-card-title>История операций по кошельку</v-card-title>
    <v-card-actions>
      <v-table class="w-100" height="400">
        <thead>
        <tr>
          <th>
            Дата и время
          </th>
          <th>
            Сумма, рубли
          </th>
          <th>
            Действие
          </th>
          <th>
            Описание
          </th>
        </tr>
        </thead>
        <tbody v-if="!loadingHistory">
        <template  v-for="row in historyList" :key="row">
          <tr v-if="visible[row.typeProcessing]">
            <td>
              {{ new Date(row.timestampCreate).toLocaleString() }}
            </td>
            <td>
                  <span :class="{
                    'text-green': row.typeProcessing === 'REFILL',
                  'text-red': row.typeProcessing === 'WRITE_OFF'}">
                    <span v-if="row.typeProcessing === 'REFILL'">
                      +
                    </span>
                    <span v-if="row.typeProcessing === 'WRITE_OFF'">
                      -
                    </span>
                    {{ row.amount }}</span>
            </td>
            <td>
              {{ this.mapTypeProcessing[row.typeProcessing] }}
            </td>
            <td>
              {{ row.description }}
            </td>
          </tr>
        </template>
        </tbody>
      </v-table>
    </v-card-actions>
  </v-card>
</template>

<script>
import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";
import userProfileData from "@/mixins/userProfileData";

export default {
  name: "CardHistoryBalance",
  mixins: [userProfileData],
  data() {
    return {
      historyList: undefined,
      loadingHistory: true,
      visible: {
        "REQUEST_REFILL": false,
        "REFILL": true,
        "WRITE_OFF": true
      },
      mapTypeProcessing: {
        "REFILL": "Пополнение",
        "REQUEST_REFILL": "Запрос на пополнение",
        "WRITE_OFF": "Списание"
      }
    }
  },
  mounted(){
    this.updateHistory()
  },
  methods: {
    updateHistory(){
      const path = config.api + "/user/profile/balance/history";
      this.loadingHistory = true;
      axios.get(path, {
        headers: api.getHeadersLogin()
      }).then(value => {
        this.historyList = value.data
        this.historyList.forEach(v => {
          let model
          try {
            model = JSON.parse(v.modelDescription).description
          }catch (e) {
            model = v.modelDescription
          }
          v.description = model
        })
      }).catch(reason => {

      }).finally(() => {
        this.loadingHistory = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
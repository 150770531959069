<template>
  <v-card>
    <v-card-title>
      О проекте
    </v-card-title>
    <v-card-text>
      <p>
        <b>Привет!</b> Нужен программный агент с удобным ВЕБ-управлением? Тогда тебе к нам =)
      </p>
      <br>
      <p>
        <b>Программный агент</b> - это программа, которая будет выполнять твои задачи, автоматику, общаться с пользователем, взаимодействуя с ним и другими программными агентами.
      </p>
      <br>
      <p>
        Честно, это любая программа, но мы акцентируем внимание на ботах в соц сетях, а в частности - это <b><a href="https://telegram.org/">Телеграм</a> боты</b>.
      </p>
      <br>
      <p>
        Данный сайт поможет удобно управлять программным агентом:
      </p>
      <ul>
        <li>Просматривать его данные в виде таблиц и графиков</li>
        <li>Управлять программным агентом с помощью кнопок, настраивать его удобно, без умения программировать</li>
        <li>Смотреть как движется процессы внутри, что он делает, знать как бьётся "сердечко" агента</li>
        <li>... и многое другое - проект активно развивается!</li>
      </ul>
      <br>
      <p>
        Как происходит разработка можно ознакомиться на <router-link to="/price-list">этой</router-link> странице или связаться с нами по <router-link to="/contacts">контактам</router-link>.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AboutTheProjectCard"
}
</script>

<style scoped>
ol{
  padding: 0 30px;
}
</style>
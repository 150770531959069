<template>
  <v-container>
    <v-row class="justify-center text-center">
      <v-col class="v-col-md-4 v-col-12">
        <v-card>
          <v-card-title>
            Информация
          </v-card-title>
          <v-card-text>
            <b>Успешная оплата!</b><br>
            В течении 5 минут средства поступат на счёт =)
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PayRedirectView"
}
</script>

<style scoped>

</style>
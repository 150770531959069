<template>
  <v-card>
    <v-card-actions v-if="tablesPreview && tablesPreview.length !== 0">
      <v-row class="pa-2">
        <v-col class="v-col-12" v-for="table in tablesPreview" :key="table.name">
          <item-list-show-info :title="table.title" :description="table.description" :btn-title="'Открыть'"
                               :function-click="function (){table.open = !table.open}"/>


          <v-dialog fullscreen transition="dialog-bottom-transition" v-model="table.open">
            <program-agent-table-card :tableName="table.name" :agentId="agentInfo.id"
                                      :closeFunc="()=>{table.open = false}"/>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text v-else class="text-center">
      Данные отсутствуют
    </v-card-text>
  </v-card>
</template>

<script>
import ProgramAgentTableCard from "@/components/ProgramAgentTableCard.vue";
import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";
import ItemListShowInfo from "@/components/mictro/ItemListShowInfo.vue";

export default {
  name: "ProgramAgentListTablesCard",
  components: {ItemListShowInfo, ProgramAgentTableCard},
  data() {
    return {
      tablesPreview: []
    }
  },
  props: {
    agentInfo: {type: Object}
  },
  mounted() {
    if (!this.agentInfo.online)
      return;
    let pathApiTablesPreview = config.api + "/user/agent/table/preview"
    axios.get(pathApiTablesPreview, {
      params: {
        id: this.agentInfo.id,
      },
      headers: api.getHeadersLogin()
    }).then(value => {
      this.tablesPreview = value.data
      this.tablesPreview.forEach(value1 => value1.open = false)
    }).catch(reason => {
      console.log(reason)
    })
  }
}
</script>

<style scoped>

</style>
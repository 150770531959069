<template>
  <v-container>
    <v-row class="d-md-flex justify-center" v-if="!!userData">
      <v-col class="v-col-12">
        <user-profile-card :user-data="userData"/>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import UserProfileCard from "@/components/UserProfileCard.vue";
import {mapState} from "vuex";

export default {
  name: "ProfileView",
  components: {UserProfileCard},
  computed: {
    ...mapState({
      userData: (state) => state.profile.userData
    })
  },
}
</script>

<style scoped>

</style>
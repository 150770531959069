import api from "@/api/api";
import axios from "axios";
import config from "@/api/config";


const apiLoadAllProgramAgents = config.api + '/user/agent/all';
const apiLoadInfoProgramAgents = config.api + '/user/agent';
const apiCreateProgramAgent = config.api + '/user/agent/create';
const apiLoadHealthInfoProgramAgent = config.api + '/user/agent/health';


const apiStartProgramAgent = config.api + '/user/agent/start';
const apiStopProgramAgent = config.api + '/user/agent/stop';

const apiProgramAgentChartsPreview = config.api + '/user/agent/charts/preview';
const apiProgramAgentChartData = config.api + '/user/agent/chart';
const apiAgentConnectUser = config.api + '/user/agent/connect';

const agentPageNotification = config.api + '/user/agent/notification/page';

export default {
    notificationPage: function (id, page, size, ok, error) {
        api.executeDefaultGetParam(agentPageNotification, {agentId: id, size: size, page: page}, ok, error);
    },
    loadAllProgramAgents: function (ok, error) {
        api.executeDefaultGet(apiLoadAllProgramAgents, ok, error);
    },
    loadInfoProgramAgents: function (id, ok, error) {
        axios.get(apiLoadInfoProgramAgents, {
            params: {
                id: id
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    loadHealthInfoProgramAgents: function (id, ok, error) {
        api.executeDefaultGetParam(apiLoadHealthInfoProgramAgent, {
            id: id
        }, ok, error);
    },
    createProgramAgent(data, ok, error) {
        api.executeDefaultPost(apiCreateProgramAgent, data, ok, error);
    },
    startProgramAgent: function (id, ok, error) {
        axios.get(apiStartProgramAgent, {
            params: {
                id: id
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    stopProgramAgent: function (id, ok, error) {
        axios.get(apiStopProgramAgent, {
            params: {
                id: id
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    getAllChartsPreview: function (id, ok, error) {
        axios.get(apiProgramAgentChartsPreview, {
            params: {
                id: id
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    getChartData: function (id, name, from, to, group, ok, error) {
        axios.get(apiProgramAgentChartData, {
            params: {
                id: id,
                name: name,
                from: from,
                to: to,
                group: group,
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    },
    agentConnectUser: function (id, customerId, developerId, ok, error) {
        axios.post(apiAgentConnectUser, {}, {
            params: {
                id: id,
                customerId: customerId,
                developerId: developerId,
            },
            headers: api.getHeadersLogin(),
        }).then(value => {
            ok(value)
        }).catch(reason => {
            error(reason)
        })
    }
}
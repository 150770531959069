import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";
import ProfileView from "@/views/ProfileView";
import LogoutView from "@/views/LogoutView";
import ProgramAgentListView from "@/views/ProgramAgentListView";
import ProgramAgentView from "@/views/ProgramAgentView";
import CreateProgramAgentView from "@/views/CreateProgramAgentView";
import NotFoundView from "@/views/NotFoundView";
import RegistrationView from "@/views/RegistrationView";
import UsersListView from "@/views/UsersListView";
import ProgramAgentPaymentsAllListView from "@/views/ProgramAgentPaymentsAllListView.vue";
import priceList from "@/views/PriceList.vue";
import RegistrationVerify from "@/views/RegistrationVerify.vue";
import RecoveryPasswordPage from "@/views/RecoveryPasswordPage.vue";
import RecoveryPasswordVerifyPage from "@/views/RecoveryPasswordVerifyPage.vue";
import PayRedirectView from "@/views/PayRedirectView.vue";
import UserWalletPage from "@/views/UserWalletPage.vue";
import ProjectNewsView from "@/views/ProjectNewsView.vue";
import ContactView from "@/views/ContactView.vue";
import UserNotificationView from "@/views/UserNotificationView.vue";


const routes = [
    {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView},
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/registration',
        name: 'registration',
        component: RegistrationView
    },
    {
        path: '/users-list',
        name: 'usersList',
        component: UsersListView
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactView
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutView
    },
    {
        path: '/create-program-agent',
        name: 'createProgramAgent',
        component: CreateProgramAgentView
    },
    {
        path: '/program-agents',
        name: 'programAgents',
        component: ProgramAgentListView
    },
    {
        path: '/program-agent/:id',
        name: 'programAgentId',
        component: ProgramAgentView
    },
    {
        path: '/payments/program-agent',
        name: 'program-agent-payments',
        component: ProgramAgentPaymentsAllListView
    },
    {
        path: '/price-list',
        name: 'price-list',
        component: priceList
    },
    {
        path: '/registration/verify',
        name: 'registration-verify',
        component: RegistrationVerify
    },
    {
        path: '/recovery-password',
        name: 'recovery-password',
        component: RecoveryPasswordPage
    },
    {
        path: '/recovery-password/verify',
        name: 'recovery-password/verify',
        component: RecoveryPasswordVerifyPage
    },
    {
        path: '/pay/c',
        name: 'pay/c',
        component: PayRedirectView
    },
    {
        path: '/profile/wallet',
        name: 'profile/wallet',
        component: UserWalletPage
    },
    {
        path: '/project/news',
        name: 'project/news',
        component: ProjectNewsView
    },
    {
        path: '/user/notification',
        name: 'user/notification',
        component: UserNotificationView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

import api from "@/api/api";
import axios from "axios";
import config from "@/api/config";

const apiContactData = config.api + '/user/profile/contact';
const pathAllUsers = config.api + '/user/all';
const pathAllNotificationUser = config.api + '/user/notification/all';
const pathAllNotificationUserNew = config.api + '/user/notification/all/new';
const readNotification = config.api + '/user/notification/read';
const countNewNotification = config.api + '/user/notification/new/count';

export default {
    setContactData: function (data, ok, error) {
        axios.post(apiContactData,
            data,
            {
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
    getAllUserList: function (ok, error) {
        axios.get(pathAllUsers,
            {
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
    getAllNotification: function (ok, error) {
        axios.get(pathAllNotificationUser,
            {
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
    getAllNotificationNew: function (ok, error) {
        axios.get(pathAllNotificationUserNew,
            {
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
    readNotification: function (id, ok, error) {
        axios.get(readNotification,
            {
                params: {'notificationId' : id},
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
    getCountNewNotification: function (ok, error) {
        axios.get(countNewNotification,
            {
                headers: api.getHeadersLogin(),
            })
            .then(value => {
                ok(value)
            })
            .catch(reason => {
                error(reason)
            })
    },
}
<template>
  <v-card>
    <v-toolbar
        color="indigo-darken-4"
    >
      <v-toolbar-title>
        {{ preview.nameDisplay }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
      </v-toolbar-items>
      <v-btn
          icon
          dark
          @click="closeFunc()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      {{ preview.description }}
    </v-card-text>
    <v-card-actions v-if="preview.isAbilityGroupTimestamp">
      <v-row>
        <v-col cols="12" class="ma-1">
          Группировать точки по:
        </v-col>
        <v-col v-for="type in groupTimes" :key="type.type">
          <v-btn variant="outlined" block @click="updateGroup(type)" :disabled="selectTypes === type.type">
            {{ type.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <apexchart ref="chartTime" class="pa-5" width="100%" :height="chartHeight" :series="seriesDataFull"
                 :options="options"/>
    </v-card-text>
    <v-card-actions v-if="!fullscreenMode">
      <v-btn block variant="outlined">
        Во весь экран
        <v-dialog activator="parent">
          <program-agent-chart-timestamp-view-card
              :program-agent="programAgent" :preview="preview"
              :fullscreenMode="true" :chart-height="300"/>
        </v-dialog>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/api/api";
import vuetify from "@/plugins/vuetify";
import userManagerAgentApi from "@/api/userManagerAgentApi";

export default {
  name: "ProgramAgentChartTimestampViewCard",
  data() {
    return {
      seriesData: [],
      selectTypes: 'NO',
      groupTimes: [
        {
          type: "NO",
          name: "Без группировки"
        }, {
          type: "MINUTE",
          name: "Минутам"
        }, {
          type: "HOUR",
          name: "Часам"
        }, {
          type: "DAY",
          name: "Дням"
        }, {
          type: "WEEK",
          name: "Неделям"
        }, {
          type: "MONTH",
          name: "Месяцам"
        }
      ],
    }
  },
  props: {
    programAgent: Object,
    preview: Object,
    fullscreenMode: {
      type: Boolean,
      default: false
    },
    chartHeight: {
      type: Number,
      default: 400
    },
    closeFunc: {
      type: Function,
      default: function (){}
    }
  },
  mounted() {
    if (this.programAgent.online)
      this.loadSeries()
  },
  methods: {
    updateGroup(type) {
      this.selectTypes = type.type
      this.loadSeries()
    },
    updateChart() {
      this.$refs.chartTimeFull.updateSeries([{
        data: this.seriesData
      }], false, true);
    },
    loadSeries() {
      console.log(this.preview.fromTime)
      console.log(this.seriesData)
      this.seriesData.length = 0
      userManagerAgentApi.getChartData(this.programAgent.id,
          this.preview.name,
          this.preview.fromTime,
          this.preview.toTime,
          this.selectTypes,
          (ok) => {
            let points = ok.data.points
            for (const pointsKey in points) {
              this.seriesData.push({x: new Date(points[pointsKey].time), y: points[pointsKey].value})
            }
            // this.$refs.chartTime.updateSeries([{
            //   data: this.seriesData
            // }], false, true);
          },
          (err) => {
            console.log(err)
          })
    },
  },
  computed: {
    seriesDataFull() {
      return [{
        name: this.preview.name,
        data: this.seriesData
      }];
    },
    options() {
      return {
        stroke: {
          curve: 'straight',
        },
        chart: {
          id: 'vuechart-example',
        },
        markers: {
          size: 4,
        },
        xaxis: {
          type: "datetime",
          title: {
            text: "Время"
          }
        },
        yaxis: {
          title: {
            text: "Пользователи"
          }
        },
        noData: {
          text: 'Loading...'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-actions v-if="previewCharts && previewCharts.length !== 0">
      <v-row class="pa-2">
        <v-col class="v-col-12" v-for="chart in previewCharts" :key="chart">

          <item-list-show-info :title="chart.nameDisplay" :description="chart.description" :btn-title="'Открыть'"
                               :function-click="function (){chart.open = !chart.open}"/>

          <v-dialog fullscreen transition="dialog-bottom-transition" v-model="chart.open">
            <program-agent-chart-timestamp-view-card :program-agent="agentInfo" :preview="chart"
                                                     :fullscreenMode="true" :close-func="()=>{chart.open = false}"/>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>

    <v-card-text v-else class="text-center">
      Данные отсутствуют
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api/api";
import ProgramAgentChartTimestampViewCard from "@/components/ProgramAgentChartTimestampViewCard.vue";
import ItemListShowInfo from "@/components/mictro/ItemListShowInfo.vue";
import userManagerAgentApi from "@/api/userManagerAgentApi";

export default {
  name: "ProgramAgentListChartTimestamp",
  components: {ItemListShowInfo, ProgramAgentChartTimestampViewCard},
  data() {
    return {
      previewCharts: undefined
    }
  },
  props: {
    agentInfo: {type: Object}
  },
  mounted() {
    this.loadChartsPreview();
  },
  methods: {
    loadChartsPreview() {
      if (!this.agentInfo.online)
        return;
      return userManagerAgentApi.getAllChartsPreview(this.agentInfo.id, (result) => {
        this.previewCharts = result.data
        this.previewCharts.forEach(value1 => value1.open = false)
      }, (error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
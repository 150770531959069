<template>
  <v-container>
    <v-row class="d-md-flex justify-center">
      <v-col class="v-col-12">
        <v-card>
          <v-card-actions>
            <v-row class="align-center">
              <v-col class="v-col-sm-8 v-col-12">
                <v-text-field placeholder="Поиск" v-model="findProgramAgent" class="mt-3">
                </v-text-field>
              </v-col>
              <v-col class="v-col-sm-4 v-col-12">
                <v-btn :disabled="loadingProcess" block variant="outlined" @click="updateAgents">
                  Обновить список
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="v-col-12" v-if="isAuth && isAdmin">
        <v-card>
          <v-card-actions>
            <v-btn block variant="outlined" to="/create-program-agent">
              Создать нового бота
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-row v-if="(filterProgramAgentList === undefined || filterProgramAgentList.length === 0) && loadingProcess">
      <v-col>
        <v-progress-linear indeterminate
                           color="green darken-10">

        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="(filterProgramAgentList === undefined || filterProgramAgentList.length === 0) && !loadingProcess">
      <v-col>
        <v-card>
          <v-card-text class="text-center">
            <span v-if="isAuth">Нет доступных агентов</span>
            <span v-if="!isAuth">Отсутствуют агенты для общего доступа</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!!filterProgramAgentList">
      <v-col class="v-col-12 v-col-md-6" v-for="agent in filterProgramAgentList" key="agent.id">
        <program-agent-description :bot="agent" :preview="true" :link="'/program-agent/' + agent.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgramAgentDescription from "@/components/ProgramAgentDescription";
import {mapState, mapMutations, mapGetters, mapActions} from "vuex"
import userProfileData from "@/mixins/userProfileData";

export default {
  name: "ProgramAgentListView",
  components: {ProgramAgentDescription},
  mixins: [userProfileData],
  data() {
    return {
      loadingProcess: false,
      findProgramAgent: ""
    }
  },
  computed: {
    ...mapState({
      programAgentsList: state => state.programAgents.programAgents,
      isAuth: state => state.profile.isAuth
    }),
    filterProgramAgentList() {
      if (!!this.programAgentsList) {
        return this.programAgentsList.filter((agent) => {
          return JSON.stringify(agent).toLowerCase().includes(this.findProgramAgent.toLowerCase());
        });
      }
      return this.programAgentsList
    }
  },
  methods: {
    ...mapActions({
      loadAll: "programAgents/loadAll"
    }),
    loadAgents() {
      this.loadingProcess = true
      this.loadAll({
        ok: () => {
          this.loadingProcess = false
        },
        error: () => {
          setTimeout(() => {
            this.loadingProcess = false
          }, 700)
        }
      })
    },
    updateAgents() {
      this.loadAgents()
    }
  },
  mounted() {
    this.loadAgents()
  }
}
</script>

<style scoped>

</style>
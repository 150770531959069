<script>
import programAgentsMethod from "@/mixins/programAgentsMethod";

export default {
  name: "UserNotificationLine",
  mixins: [programAgentsMethod],
  props: {
    notifyData: {type: Object},
    showSender: {type: Boolean, default: true}
  },
  methods: {
    toStringDate: function (date) {
      return date.getHours() + ":" + date.getMinutes() + " " + date.toLocaleDateString()
    },
  }
}
</script>

<template>
  <v-col class="border-b border-opacity-25" cols="12">

    <span :style="{'color': notifyData.read ? 'grey' : 'blue'}">● </span>
    <span v-if="showSender">

    <span v-if="notifyData.senderType === 'SYSTEM'">От системы </span>
    <span v-if="notifyData.senderType === 'AGENT'">От агента
                  <router-link :to="'/program-agent/' + getProgramAgent(notifyData.senderId).id"><b>{{
                      getProgramAgent(notifyData.senderId).name
                    }}</b></router-link>
                </span>
    ->
    </span>
    <b> {{ notifyData.title }}</b>

    <br>

    <span class="pl-2 text-grey-darken-1">
                {{ toStringDate(new Date(notifyData.createTimestamp)) }} важность: <span
        :class="{'text-green-darken-3': notifyData.priority === 'LOW', 'text-blue-darken-3': notifyData.priority === 'MEDIUM', 'text-red-darken-3': notifyData.priority === 'HIGH'}">{{
        notifyData.priority
      }}</span>
                </span>
    <br>
    <span class="pl-5 text-grey-darken-4">
                  {{ notifyData.description }}
                </span>
  </v-col>
</template>

<style scoped>

</style>
<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Как происходит разработка
          </v-card-title>
          <v-card-text>
            <ul>
              <li>Вы связываетесь с нами по
                <router-link to="/contacts">контактным данным</router-link>
              </li>
              <ul>
                <li>Обсуждаем функции агента</li>
                <li>Рассчитываем сроки</li>
                <li>Определяемся со стоимостью работ</li>
              </ul>
              <li>Мы разрабатываем агента</li>
              <li style="color: green"><b>Вы получаете своего агента!</b></li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Цены
          </v-card-title>
          <v-card-text>
            <p>
              Оплата программного агента состоит из двух частей:
            </p>
            <ul>
              <li>Стоимость разработки</li>
              <li>Ежемесячная оплата</li>
            </ul>
            <br>
            <p>
              <b>Стоимость разработки</b>
            </p>
            <p>
              Цена за разработку учитывает часы и трудозатраты. В среднем, час разработки стоит <b>1 000р</b>.
            </p>
            <p>
              Минимальная стоимость заказа составляет <b>5 000р</b> - разработка, отладка, развертывание, запуск
            </p>
            <br>
            <p>
              <b>Ежемесячная оплата</b>
            </p>
            <p>
              Оплата в месяц складывается из аренды сервера, по умолчанию у каждого программного агента свой
              сервер, и стоимость использование системы, включая:
            </p>
            <ul>
              <li>Просмотр данных агента
                <ul>
                  <li>Таблицы</li>
                  <li>Графики</li>
                  <li>Процессы</li>
                </ul>
              </li>
              <li>
                Управление состоянием агента
                <ul>
                  <li>Формы ввода данных</li>
                </ul>
              </li>
            </ul>
            <p>
              Минимальная стоимость аренды сервера составляет <b>600р</b> - покрывает расходны на сервера и поддержку.
              Позволяет заказчику пользоваться полноценно всей системой
            </p>
            <br>
            <p><b>Итого</b></p>
            <ul>
              <li><b>5 000р</b> за разработку, из них предоплата в начале 2 500р</li>
              <li><b>600р</b> каждый месяц за его работу</li>
            </ul>
            <br>
            <p>
              Данные цены минимальные для заказа. Как пример такого типового бота за такую цену, это:
            </p>
            <ul>
              <li>Бот-калькулятор - личный бот, который будет помогать Вам считать, а может и Вашей команде</li>
              <li>Предоставление данных от API другой системы - может подключиться к Вашей системе и общаться с ней,
                через интерфейс мессендежера
              </li>
              <li>Обработчик изображений - обработает сообщения с картинками, изменив цветовую палитру</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PriceList",
  data() {
    return {
      cardClass: "v-col-md-4 v-col-12",
      priceList: [
        {
          "name": "Для себя",
          "description": "Простой проект для себя и своих близких. Частному лицу базовый пакет",
          "priceDev": 2500.0,
          "priceOps": 1000.0,
          "priceServer": 300.0,
          "tag": ["telegram", "vk", "вычисления"]
        },
        {
          "name": "Для аудитории",
          "description": "Программный агент, а точнее социальный бот для общего пользования маленькой группы",
          "priceDev": 10000.0,
          "priceOps": 5000.0,
          "priceServer": 1000.0,
          "tag": ["telegram", "vk"]
        },
        {
          "name": "Для крупных площадок",
          "description": "Для крупных клиентов, с большим потоком пользователей",
          "priceDev": 30000.0,
          "priceOps": 10000.0,
          "priceServer": 5000.0,
          "tag": ["telegram", "vk"]
        },
      ]
    }
  }
}
</script>

<style scoped>
</style>
<script>
import NotificationAlert from "@/components/NotificationAlert.vue";
import userApi from "@/api/userApi";
import userProfileData from "@/mixins/userProfileData";
import usersMethod from "@/mixins/usersMethod";

const MAP_PRIORITY = {
  'LOW': 'success',
  'MEDIUM': 'info',
  'HIGH': 'warning'
}

export default {
  name: "NotificationShow",
  components: {NotificationAlert},
  mixins: [usersMethod],
  data() {
    return {
      alerts: [],
      showsId: []
    }
  },
  mounted() {
    this.updateNew()
  },
  methods: {
    create: function (id, title, text, type) {
      if (this.showsId.find(value => value === id) !== undefined)
        return
      this.showsId.push(id)
      const rt = 10000
      this.createAndStart({id: id, title: title, text: text, type: type, time: rt, delayTime: rt})
    },
    createAndStart(data) {
      this.alerts.unshift(data)
    },
    remove(alert) {
      const i = this.alerts.indexOf(alert)
      this.alerts.splice(i, 1)
    },
    updateNew() {
      if(!this.isAuth){
        setTimeout(() => this.updateNew(), 10000)
        return
      }
      userApi.getAllNotificationNew(ok => {
        ok.data.forEach(v => {
          this.create(v.id, v.title, v.description, MAP_PRIORITY[v.priority])
        })
        setTimeout(() => this.updateNew(), 5000)
      }, error => {
      })
    }
  }
}
</script>

<template>
  <div class="notification-panel">

    <notification-alert
        v-for="alert in alerts"
        :key="alert"
        :data="alert"
        :close-func="remove"
    />
  </div>
</template>

<style scoped>

.notification-panel {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: none;
}

</style>
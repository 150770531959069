<script setup>

import {ref} from "vue";

const props = defineProps(['functionSendAction', 'formData'])

const userDataAction = JSON.parse(props.formData.text)

console.log(userDataAction)

    // [
    // {
    //   'title': "@awdwaed Еше вфц4151 (подписка)",
    //   "value": {id: 1, subscription: true}
    // },
    //     {
    //       'title': "@dcawd awЕше1 1515",
    //       "value": {id: 2, subscription: false}
    //     },
    //     {
    //       'title': "@hkdsedaweraraw Еше2765 56 (подписка)",
    //       "value": {id: 3, subscription: true}
    //     }
    // ]

const userData = userDataAction.map((user) => {
  const data = {
    'title': user.userSystemId + '. @' + user.userNameTelegram + (user.subscription ? " (подписка)" : ""),
    'value': {
      id: user.userSystemId,
      subscription: user.subscription
    }
  }
  return data
})


const selectValue = ref()

function createModelSendAdd() {
  return JSON.stringify({"type": "ADD", "userSystemId": selectValue.value.id})
}

function createModelSendDelete() {
  return JSON.stringify({"type": "DELETE", "userSystemId": selectValue.value.id});
}

</script>

<template>
  <v-row class="ma-2">
    <v-col class="v-col-12">
      <v-card>
        <v-card-title>
          Управление подписками
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Введите имя пользователя или его id
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                  v-model="selectValue"
                  :items="userData"
                  label="Выбор пользователя">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="selectValue !== null && selectValue !== undefined">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col v-if="selectValue.subscription">
              Подписка на пользователе будет удалена
            </v-col>
            <v-col v-else>
              Создание подписки у пользователя
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col v-if="selectValue.subscription">
                  <v-btn class="btn-center" color="green" variant="outlined"
                         @click="functionSendAction(formData.name, createModelSendDelete())">
                    Удалить подписку
                  </v-btn>
                </v-col>
                <v-col v-else>
                  <v-btn class="btn-center" color="green" variant="outlined"
                         @click="functionSendAction(formData.name, createModelSendAdd())">
                    Добавить подписку
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
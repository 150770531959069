<script>
import userApi from "@/api/userApi";
import {ref} from "vue";
import programAgentsMethod from "@/mixins/programAgentsMethod";
import usersMethod from "@/mixins/usersMethod";
import isAuthViewRedirect from "@/mixins/isAuthViewRedirect";
import UserNotificationLine from "@/components/mictro/UserNotificationLine.vue";

export default {
  components: {UserNotificationLine},
  mixins: [isAuthViewRedirect],
  mounted() {
    this.loadNotification();
  },
  setup() {
    const notifications = ref()

    function readNotification(id) {
      return userApi.readNotification(id, (ok) => {
      }, error => {
      })
    }

    function loadNotification() {
      return userApi.getAllNotification((ok) => {
        notifications.value = ok.data
        for (let notf of notifications.value) {
          if (!notf.read)
            readNotification(notf.id)
        }
      }, error => {
      })
    }

    return {
      notifications,
      loadNotification
    }
  }
}
</script>

<template>
  <v-container>
    <v-row class="d-md-flex justify-center">
      <v-col>

        <v-card>
          <v-card-title>
            Уведомления
          </v-card-title>
          <v-card-text v-if="notifications === undefined || notifications.length === 0">
            Пусто
          </v-card-text>
          <v-card-text v-if="notifications !== undefined && notifications.length !== 0">
            <v-row>
              <user-notification-line v-for="notf in notifications" :key="notf.id" :notify-data="notf"/>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
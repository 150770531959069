<template>
  <v-card v-if="!!bot">
    <v-card-title class=" d-flex justify-space-between">
      <span>
        <span v-if="bot.online" style="color: green">◉</span>
        <span v-if="!bot.online" style="color: indianred">◉</span>
        {{ bot.name }}
      </span>
      <v-btn v-if="!preview" icon="mdi-reload" size="small" @click="updateDataFunc()">
      </v-btn>
    </v-card-title>
    <v-card-text class="">
      {{ bot.description }}
    </v-card-text>
    <v-card-actions v-if="preview">
      <v-row>
        <v-col class="v-col-12">
          <v-btn block variant="outlined" :to="link">
            Открыть
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import usersMethod from "@/mixins/usersMethod";
import {mapGetters} from "vuex";

export default {
  name: "ProgramAgentDescription",
  mixins: [usersMethod],
  data() {
    return {}
  },
  props: {
    bot: Object,
    preview:
        {
          type: Boolean,
          default: false
        },
    link: String,
    updateDataFunc: {
      type: Function, default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      getUser: "users/getUserById",
    }),
  },
}
</script>

<style scoped>

</style>
<template>
  <v-app>
    <navbar-view></navbar-view>
    <v-main>
      <v-container class="pa-0 pa-md-6 pa-lg-8" style="max-width: 1280px">
        <router-view/>
      </v-container>
    </v-main>
    <NotificationShow/>
  </v-app>
</template>

<script>


import NavbarView from "@/views/NavbarView";
import NotificationShow from "@/components/NotificationShow.vue";

export default {
  name: 'App',
  components: {
    NotificationShow,
    NavbarView
  },
  data: () => ({
    showInfo: false
    //
  }),
}
</script>

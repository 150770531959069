<template>
  <v-container v-if="!!agentInfo">

    <v-row>
      <v-col class="v-col-12">
        <program-agent-description :bot="agentInfo" :update-data-func="thisUpdateData"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-tabs
              bg-color="indigo-darken-4"
              center-active
              show-arrows
              align-tabs="center"
              v-model="tabInfo">

            <v-tab value="info">
              Информация
            </v-tab>
            <v-tab value="tables">
              Таблицы
            </v-tab>
            <v-tab value="charts">
              Графики
            </v-tab>
            <v-tab value="actions">
              Действия
            </v-tab>
<!--            <v-tab value="payments">-->
<!--              Платежи-->
<!--            </v-tab>-->
            <v-tab value="settings">
              Настройки
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-window v-model="tabInfo" class="pa-2 ma-0">
      <v-window-item value="info">
        <v-row>
          <v-col :class="cardStyle">
            <v-card>
              <v-card-text class="ma-2">
                <v-row>
                  <v-col class="v-col-3">
                    Наименование:
                  </v-col>
                  <v-col class="v-col-9">
                    {{agentInfo.name}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="v-col-3">
                    Статус:
                  </v-col>
                  <v-col class="v-col-9">
                    <span v-if="agentInfo.online" style="color: green">Онлайн</span>
                    <span v-else style="color: red">Оффлайн</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="v-col-3">
                    Описание:
                  </v-col>
                  <v-col class="v-col-9">
                    {{agentInfo.description}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="v-col-3">
                    Создан:
                  </v-col>
                  <v-col class="v-col-9">
                    {{ new Date(agentInfo.createTime).toLocaleString() }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :class="cardStyle">
            <agent-notification-card :agent-id="getAgentId"/>
          </v-col>
          <v-col :class="cardStyle">
            <program-agent-progress-bars-card :agent-info="agentInfo"/>
          </v-col>
          <v-col :class="cardStyle" v-if="isAdmin">
            <program-agent-server-health-info-card :program-agent="agentInfo"/>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item value="tables">
        <v-row>
          <v-col :class="cardStyle">
            <program-agent-list-tables-card :agent-info="agentInfo"/>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item value="charts">
        <v-row>
          <v-col :class="cardStyle">
            <program-agent-list-chart-timestamp :agent-info="agentInfo"/>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item value="actions">
        <v-row>
          <v-col :class="cardStyle">
            <program-agent-form-actions-card :agent-info="agentInfo"/>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item value="settings">
        <v-row>
          <v-col :class="cardStyle" v-if="isAdmin">
            <program-agent-user-manager-card :program-agent="agentInfo"/>
          </v-col>
          <v-col :class="cardStyle" v-if="isAdmin">
            <program-agent-token-visible :program-agent="agentInfo"/>
          </v-col>
          <v-col v-else>
            <v-card>
              <v-card-text class="text-center">
                Данные отсутствуют
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
import ProgramAgentDescription from "@/components/ProgramAgentDescription";
import isAuthViewRedirect from "@/mixins/isAuthViewRedirect";
import ProgramAgentTokenVisible from "@/components/ProgramAgentTokenVisible";
import ProgramAgentServerHealthInfoCard from "@/components/ProgramAgentServerHealthInfoCard";
import ProgramAgentDataHealthInfoCard from "@/components/ProgramAgentDataHealthInfoCard";
import ProgramAgentChartTimestampViewCard from "@/components/ProgramAgentChartTimestampViewCard";
import ProgramAgentUserManagerCard from "@/components/ProgramAgentUserManagerCard";
import programAgentPaymentsApi from "@/api/programAgentPaymentsApi";
import ProgramAgentPaymentsInfoCard from "@/components/ProgramAgentPaymentsInfoCard.vue";
import ProgramAgentTableCard from "@/components/ProgramAgentTableCard.vue";
import ProgramAgentListChartTimestamp from "@/components/ProgramAgentListChartTimestamp.vue";
import ProgramAgentListTablesCard from "@/components/ProgramAgentListTablesCard.vue";
import usersMethod from "@/mixins/usersMethod";
import ProgramAgentProgressBarsCard from "@/components/ProgramAgentProgressBarsCard.vue";
import ProgramAgentFormActionsCard from "@/components/ProgramAgentFormActionsCard.vue";
import userManagerAgentApi from "@/api/userManagerAgentApi";
import UserNotificationLine from "@/components/mictro/UserNotificationLine.vue";
import AgentNotificationCard from "@/components/agent/AgentNotificationCard.vue";

export default {
  name: "ProgramAgentView",
  data() {
    return {
      tabInfo: null,
      cardStyle: 'v-col-12',
      paymentsArray: []
    }
  },
  components: {
    AgentNotificationCard,
    UserNotificationLine,
    ProgramAgentFormActionsCard,
    ProgramAgentProgressBarsCard,
    ProgramAgentListTablesCard,
    ProgramAgentListChartTimestamp,
    ProgramAgentTableCard,
    ProgramAgentPaymentsInfoCard,
    ProgramAgentDataHealthInfoCard,
    ProgramAgentUserManagerCard,
    ProgramAgentServerHealthInfoCard,
    ProgramAgentTokenVisible,
    ProgramAgentDescription,
    ProgramAgentChartTimestampViewCard,
  },
  mixins: [isAuthViewRedirect, usersMethod],
  mounted() {
    this.thisUpdateData()
  },
  destroyed() {
    this.stopTimer()
  },
  computed: {
    ...mapState({
      programAgentsList: state => state.programAgents.programAgents,
    }),
    ...mapGetters({
      getAgentInfo: "programAgents/getAgentInfo"
    }),
    agentInfo() {
      return this.getAgentInfo(this.getAgentId)
    },
    getAgentId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      loadHealth: "programAgents/loadHealth"
    }),
    thisUpdateData() {
      if (!!this.agentInfo && this.agentInfo.online) {
        this.loadHealth({
          id: this.getAgentId,
          ok: function () {
          },
          error: function () {
          }
        })
      }
      programAgentPaymentsApi.findById(this.getAgentId, (ok) => {
        this.paymentsArray = ok.data
      }, error => {

      })
    },
  }
}
</script>

<style scoped>

</style>
<script setup>
import Markdown from 'vue3-markdown-it';

import ProjectNewsGroupListShow from "@/components/projectnews/ProjectNewsGroupListShow.vue";
import ProjectNewsPost from "@/components/projectnews/ProjectNewsPost.vue";
</script>
<script>
import axios from "axios";
import config from "@/api/config";
import userProfileData from "@/mixins/userProfileData";
import api from "@/api/api";

export default {
  mixins: [userProfileData],
  data() {
    return {
      pnews: [],
      isCreate: false,
      newPost: {title: "", content: ""}
    }
  },
  methods: {
    clearPost() {
      this.newPost = {title: '', content: '', createTimestamp: new Date().getTime()}
    },
    updateData() {
      axios.get(config.api + "/project/news/all", {})
          .then(value => {
            this.pnews = value.data;
          })
          .catch(reason => {
          })
    },
    createPost() {
      axios.post(config.api + "/project/news/create", this.newPost, {
        headers: api.getHeadersLogin()
      })
          .then(value => {
            this.clearPost()
            this.isCreate = false;
            this.updateData()
          })
          .catch(reason => {
            this.updateData()
          })
    }
  },
  mounted() {
    this.updateData()
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="isAdmin">
        <v-card v-if="!isCreate">
          <v-card-actions>
            <v-btn class="w-100" variant="outlined" @click="isCreate = true; clearPost()">
              Создать новость
            </v-btn>

          </v-card-actions>
        </v-card>
        <v-card v-if="isCreate">
          <v-card-title>
            Новый пост
          </v-card-title>
          <div class="border border-1"></div>

          <v-card-actions>
            <v-text-field v-model="newPost.title"></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-textarea v-model="newPost.content"></v-textarea>
          </v-card-actions>

          <div class="border border-1"></div>

          <ProjectNewsPost :post="newPost"/>

          <div class="border border-1"></div>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn variant="outlined" class="w-100" @click="createPost">
                  Создать
                </v-btn>
              </v-col>
              <v-col>
                <v-btn variant="outlined" class="w-100" @click="isCreate = false; clearPost()">
                  Отмена
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="text-center">
            Новости проекта
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12">
        <ProjectNewsGroupListShow :news-post="pnews" :update-data="() => {updateData()}"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
<template>
  <v-card>
    <v-card-title>
      {{ post.title }}
    </v-card-title>
    <v-card-text class="py-0">
      <i>{{ new Date(post.createTimestamp).toLocaleString() }}</i>
    </v-card-text>

    <v-card-actions v-if="isEdit">
      <v-card class="w-100">
        <v-card-actions>
          <v-text-field v-model="post.title"/>
        </v-card-actions>
        <v-card-actions>
          <v-textarea v-model="post.content"/>
        </v-card-actions>
        <v-card-actions>
          <v-btn @click="saveEdit(); isEdit = false">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-actions>

    <v-card-text class="mx-4 my-0 markdown-post mb-4">
      <Markdown :source="post.content" html/>
    </v-card-text>


    <v-card-actions v-if="isAdmin">
      <v-btn @click="deletePost(post.id)">
        Удалить
      </v-btn>
      <v-btn @click="isEdit = true">
        Редактировать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import Markdown from 'vue3-markdown-it';
</script>
<script>

import axios from "axios";
import config from "@/api/config";
import api from "@/api/api";
import userProfileData from "@/mixins/userProfileData";

export default {
  name: 'ProjectNewsPost',
  data (){
    return {
      isEdit: false
    }
  },
  methods: {
    deletePost(id) {
      axios.post(config.api + "/project/news/delete", {id: id}, {
        headers: api.getHeadersLogin()
      })
          .then(value => {
            this.updateData()
          })
    },
    saveEdit() {
      axios.post(config.api + "/project/news/edit", this.post, {
        headers: api.getHeadersLogin()
      })
          .then(value => {
            this.updateData()
          })
    },
  },
  mixins: [userProfileData],
  props: {
    post: {},
    updateData: {
      type: Function,
      default: function (){}
    }
  }
}
</script>


<style scoped>

.markdown-post >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
}

@media (min-width: 1200px) {
  .markdown-post >>> img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    height: auto;
  }
}
</style>
<template>
  <v-card>
    <v-card-actions v-if="formActions && formActions.length !== 0">
      <v-row class="pa-2">
        <v-col class="v-col-12" v-for="formAction in formActions" :key="formAction.name">
          <item-list-show-info :title="formAction.title" :description="formAction.description" :btn-title="'Открыть'"
                               :function-click="function (){formAction.open = !formAction.open}"/>
          <v-dialog fullscreen transition="dialog-bottom-transition" v-model="formAction.open">
            <v-card>

              <v-toolbar
                  color="indigo-darken-4"
              >
                <v-toolbar-title>
                  {{ formAction.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                </v-toolbar-items>
                <v-btn
                    icon
                    dark
                    @click="formAction.open = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text style="flex: 0">
                {{ formAction.description }}
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12">
                    <telegram-mailing-actions-form
                        v-if="formAction.type === 'telegram-mailing-bot'"
                        :function-send-action="processingForm"
                        :form-data="formAction">

                    </telegram-mailing-actions-form>

                    <edit-subscription-action-form
                        v-if="formAction.type === 'edit-sub'"
                        :function-send-action="processingForm"
                        :form-data="formAction">

                    </edit-subscription-action-form>

                    <v-textarea v-model="formAction.text" v-else>

                    </v-textarea>
                  </v-col>
                  <v-col cols="12" v-if="formAction.type !== 'telegram-mailing-bot' && formAction.type !== 'edit-sub'">
                    <v-btn class="btn-center" color="green" variant="outlined"
                           @click="processingFormActions(formAction.name, formAction.text, ()=>{formAction.open = false}, ()=>{})">
                      Отправить
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text v-else class="text-center">
      Данные отсутствуют
    </v-card-text>
  </v-card>
</template>

<script>
import ProgramAgentTableCard from "@/components/ProgramAgentTableCard.vue";
import config from "@/api/config";
import axios from "axios";
import api from "@/api/api";
import ItemListShowInfo from "@/components/mictro/ItemListShowInfo.vue";
import TelegramMailingActionsForm from "@/components/actionsform/TelegramMailingActionsForm.vue";
import EditSubscriptionActionForm from "@/components/actionsform/EditSubscriptionActionForm.vue";

export default {
  name: "ProgramAgentFormActionsCard",
  components: {EditSubscriptionActionForm, TelegramMailingActionsForm, ItemListShowInfo, ProgramAgentTableCard},
  data() {
    return {
      formActions: []
    }
  },
  props: {
    agentInfo: {type: Object},
  },
  mounted() {
    this.updateFormActions()
  },
  methods: {
    updateFormActions() {
      if (!this.agentInfo.online)
        return
      const path = config.api + "/user/agent/form-action/all";
      axios.get(path, {
        params: {
          id: this.agentInfo.id
        },
        headers: api.getHeadersLogin()
      }).then(value => {
        this.formActions = value.data
        this.formActions.forEach(v => {
          v.open = false
        })
      }).catch(reason => {
      })
    },
    processingForm(formName, text) {
      this.processingFormActions(formName, text, () => {
        this.formAction.open = false
      }, () => {
      })
    },
    processingFormActions(name, text, ok, error) {
      if (!this.agentInfo.online)
        return
      const path = config.api + "/user/agent/form-action/processing";
      axios.post(path, {
        name: name,
        text: text
      }, {
        params: {
          id: this.agentInfo.id
        },
        headers: api.getHeadersLogin()
      }).then(value => {
        ok()
      }).catch(reason => {
        error()
      })
    },
  }
}
</script>

<style scoped>

.btn-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 890px) {
  .btn-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
</style>
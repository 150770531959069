<template>
  <v-card>
    <v-card-title>
      Наши контакты
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <b>Телеграм</b> (предпочтительнее)
        </v-col>
        <v-col>
          <a href="https://t.me/wdeath">@wdeath</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <b>Электронная почта</b>
        </v-col>
        <v-col>
          wladimir.death@yandex.ru
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjectContactsCard"
}
</script>

<style scoped>

</style>
<script>
export default {
  name: "NotificationAlert",
  props: {
    data: {
      type: Object
    },
    closeFunc:{
      type: Function
    }
  },
  mounted() {
    this.startCloseTime()
  },
  methods: {
    startCloseTime: function () {
      setTimeout(() => {
        this.data.delayTime -= 50;
        if (this.data.delayTime <= 0) {
          this.closeFunc(this.data)
        }else{
          this.startCloseTime()
        }
      }, 50)
    }
  }
}
</script>

<template>

  <v-alert
      :title="data.title"
      border="start"
      closable
      :text="data.text"
      :type="data.type"
      class="mt-1"
  >
    <template :slot="'text'">
      <span></span>
    </template>

    <v-progress-linear
        absolute
        :model-value="Math.floor(100 * (data.delayTime / data.time))"

        color="#0003"
    ></v-progress-linear>
  </v-alert>
</template>

<style scoped>

</style>
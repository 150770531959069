<template>

  <v-container>
    <v-row class="justify-center">
      <v-col cols="12">
        <about-the-project-card/>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="text-center">
            Последние новости проекта
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <ProjectNewsGroupListShow :news-post="pnews"/>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center">
            Больше новостей - <router-link to="project/news">тут</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectContactsCard from "@/components/ProjectContactsCard.vue";
import AboutTheProjectCard from "@/components/AboutTheProjectCard.vue";
import userProfileData from "@/mixins/userProfileData";
import ProjectNewsGroupListShow from "@/components/projectnews/ProjectNewsGroupListShow.vue";
import config from "@/api/config";
import axios from "axios";

export default {
  name: "HomeView",
  components: {ProjectNewsGroupListShow, AboutTheProjectCard, ProjectContactsCard},
  mixins: [userProfileData],
  data() {
    return {
      pnews: [],
    }
  },
  created() {
  },
  mounted() {
    axios.get(config.api + "/project/news/lasts", {})
        .then(value => {
          this.pnews = value.data;
        })
        .catch(reason => {
        })
  }
}
</script>

<style scoped>

</style>
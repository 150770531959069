<script>
import UserNotificationLine from "@/components/mictro/UserNotificationLine.vue";
import userManagerAgentApi from "@/api/userManagerAgentApi";
import ProgramAgentTableCard from "@/components/ProgramAgentTableCard.vue";

const SIZE_PAGE = 15;

export default {
  name: "AgentNotificationCard",
  components: {ProgramAgentTableCard, UserNotificationLine},
  props:{
    agentId: {}
  },
  data(){
    return {
      lastNotification: [],
      isShowAll: false,
      currentPage: 0,
      listAllNotification: [],
      isEnd: false
    }
  },
  mounted() {
    this.loadDataLast()
  },
  methods: {
    showAll(){
      this.isShowAll = true
      this.currentPage = 0
      this.listAllNotification = []
      this.loadNewPage()
    },
    closeShowAll(){
      this.isShowAll = false
      this.currentPage = 0
      this.listAllNotification = []
    },
    loadDataLast(){
      userManagerAgentApi.notificationPage(this.agentId, 0, 3, ok => {
        this.lastNotification = ok.data
      }, error => {

      })
    },
    loadNewPage(){
      userManagerAgentApi.notificationPage(this.agentId, this.currentPage, SIZE_PAGE, ok => {
        this.isEnd = ok.data.length === 0 || ok.data.length < SIZE_PAGE
        this.listAllNotification = this.listAllNotification.concat(ok.data);
      }, error => {

      })
    }
  }
}
</script>

<template>

  <v-card>
    <v-card-title>
      Последние уведомления
      <v-btn variant="outlined" size="small" class="float-end" @click="showAll()">
        Показать все
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row v-if="lastNotification.length !== 0">
        <user-notification-line v-for="notf in lastNotification" :key="notf" :notify-data="notf" :show-sender="false"/>
      </v-row>
      <v-row v-else>
        <v-col>
          Пусто
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog style="max-width: 50rem" transition="dialog-bottom-transition" v-model="isShowAll">
      <v-toolbar
          color="indigo-darken-4"
      >
        <v-toolbar-title>
          Уведомления агента
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
        </v-toolbar-items>
        <v-btn
            icon
            dark
            @click="closeShowAll()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row v-if="listAllNotification.length !== 0">
            <user-notification-line v-for="notf in listAllNotification" :key="notf" :notify-data="notf" :show-sender="false"/>
            <v-col cols="12" class="text-center" v-if="!isEnd">
              <v-btn variant="outlined" size="small" @click="currentPage ++; loadNewPage()">
                Загрузить ещё
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              Пусто
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>

</style>
<script setup>

import {ref} from "vue";
import Markdown from "vue3-markdown-it";

defineProps(['functionSendAction', 'formData'])

const typeModeText = [
  {text: "Обычный текст", name: "NONE"},
  {text: "Markdown", name: "Markdown"},
  {text: "HTML", name: "html"},
  {text: "Markdown v2", name: "MarkdownV2"},
]

const typeButton = [
  {text: "Ссылка", name: "URL"},
  {text: "Мета-данные", name: "META"},
]

function createNewForm() {
  return {
    text: "",
    modeText: typeModeText[0],
    images: [],
    buttonsRow: [],
    toUser: null
  }
}

function createNewButton() {
  return {text: '', type: typeButton[0], data: '', edit: true}
}


const imgSelect = ref()
const dataSend = ref(createNewForm());

function convertToBase64(event) {
  const file = event.target.files[0];
  const reader = new FileReader();
  const newImg = {};
  console.log(event)
  reader.readAsDataURL(file);
  reader.onload = () => {
    newImg.base64textString = reader.result;
    newImg.imageName = file.name;

    imgSelect.value = null
    dataSend.value.images.push(newImg);
  };
  reader.onerror = (error) => {
    console.log('Error: ', error);
  };
}

function forShowHtml(text){
  return text.replaceAll("\n", "<br>")
}

function removeImage(index) {
  removeArray(dataSend.value.images, index);
}

function removeArray(array, index) {
  array.splice(index, 1)
}

function createModelSend() {
  const send = dataSend.value;
  const images = send.images.map(value => value.base64textString)
  const btns = []
  send.buttonsRow.forEach(rowBtn => {
    const rowNew = []
    for (let btn of rowBtn) {
      rowNew.push({
        text: btn.text,
        type: btn.type.name,
        data: btn.data
      })
    }
    btns.push(rowNew)
  })
  const model = {
    text: send.text,
    modeText: send.modeText.name,
    buttons: btns,
    images: images,
    toUser: send.toUser,
  }
  return JSON.stringify(model);
}
</script>

<template>
  <v-row class="ma-2">
    <v-col class="v-col-12 v-col-md-6">
      <v-card>
        <v-card-title>
          Конструктор сообщения
          <v-btn class="float-end" variant="outlined" color="red-lighten-1"
                 @click="dataSend = createNewForm()">
            Очистить всё
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">

              <h3 class="mb-2">Текст сообщения</h3>
              <v-select label="Вид сообщения" v-model="dataSend.modeText"
                        :items="typeModeText"
                        item-title="text"
                        return-object=""
                        item-value="name">

              </v-select>
              <v-textarea v-model="dataSend.text">

              </v-textarea>
            </v-col>

            <v-col cols="12">
              <h3>Картинки</h3>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" v-for="(imgData, index) in dataSend.images"
                       class="py-0 my-0">
                  <v-label>
                    {{ index + 1 }}.
                  </v-label>
                  {{ imgData.imageName }}
                  <v-btn icon="mdi-close-circle" class="float-end"
                         @click="removeImage(index)">
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="dataSend.images.length < 10">
                  <v-file-input
                      v-model="imgSelect"
                      variant="outlined"
                      accept="image/*"
                      label="Выбрать изображение"
                      @change="convertToBase64">
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <h3>Кнопки</h3>
            </v-col>
            <v-col cols="12">

              <v-row v-for="(btnRow, indexBtnRow) in dataSend.buttonsRow"
                     class="rounded border mb-2 border-opacity-25 bg-grey-lighten-4">
                <v-col v-for="(btn, indexBtn) in btnRow" class="v-col-12 v-col-md-6">

                  <v-row v-show="!btn.edit"
                         class="w-100 rounded border h-100 border-opacity-100 ma-0 pa-0 bg-grey-lighten-3">
                    <v-col class="text-center justify-center align-center ">
                      <v-label class="h-100 text-black">
                        {{ btn.text !== null ? btn.text : '(введите текст)' }}
                      </v-label>
                    </v-col>
                    <v-col>
                      <v-btn @click="btn.edit = true" block class="w-100 text-none text-subtitle-1"
                             variant="outlined">
                        Редактировать
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-show="btn.edit"
                         class="w-100 rounded border h-100 border-opacity-100 ma-0 pa-0 bg-grey-lighten-3">

                    <v-col cols="12" lg="6" class="pa-0 pe-lg-2">
                      <v-text-field v-model="btn.text" placeholder="Текст"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" class="pa-0 ps-lg-2">
                      <v-select label="Тип кнопки" v-model="btn.type"
                                :items="typeButton"
                                item-title="text"
                                return-object=""
                                item-value="name">
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-text-field v-model="btn.data" placeholder="Данные или ссылка"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-btn block class="w-100 text-none text-subtitle-1" @click="btn.edit = false" variant="outlined"
                             color="green">
                        Сохранить
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-btn block class="w-100 text-none text-subtitle-1" @click="removeArray(btnRow, indexBtn)"
                             variant="outlined" color="red">
                        Удалить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-btn variant="outlined" block @click="btnRow.push(createNewButton())"
                         class="text-none text-subtitle-1">
                    Добавить кнопку
                  </v-btn>
                </v-col>

                <v-btn icon="mdi-close-circle" class="float-end" @click="removeArray(dataSend.buttonsRow, indexBtnRow)">
                </v-btn>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn variant="outlined" block @click="dataSend.buttonsRow.push([])"
                         class="text-none text-subtitle-1">
                    Добавить строку кнопок
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>


    <v-col class="v-col-12 v-col-md-6">
      <v-card>
        <v-card-title>
          Предпросмотр
        </v-card-title>
        <v-card-text v-if="dataSend.images.length !== 0">
          <v-row>
            <v-col style="min-width: 30%" v-for="imgData in dataSend.images" :key="imgData">
              <v-img :src="imgData.base64textString" alt="NO IMAGE"/>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="dataSend.text === ''" class="text-grey-darken-1">
          (пусто)
        </v-card-text>
        <v-card-text v-else>
          <span v-if="dataSend.modeText.name === 'NONE'">
          {{ forShowHtml(dataSend.text) }}
          </span>

          <div v-if="dataSend.modeText.name === 'html'" v-html="forShowHtml(dataSend.text)">
          </div>

          <Markdown v-if="dataSend.modeText.name === 'Markdown'" :source="forShowHtml(dataSend.text)" html/>

          <Markdown v-if="dataSend.modeText.name === 'MarkdownV2'" :source="forShowHtml(dataSend.text)" html/>
        </v-card-text>

        <v-card-text>
          <v-row v-for="btnRow in dataSend.buttonsRow" :key="btnRow">
            <v-col v-for="btn in btnRow" :key="btn">
              <v-btn variant="outlined" class="w-100 text-none text-subtitle-1">
                {{ btn.text }} <span class="text-grey-lighten-1">({{ btn.data }})</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Отправка
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <b>Введите ID чата, куда отправить. Не заполняйте, если отправить всем пользователям бота</b>
            </v-col>
            <v-col>
              <v-text-field label="Кому отправить?" placeholder="Telegram ID" v-model="dataSend.toUser">

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

              <v-btn class="btn-center" color="green" variant="outlined"
                     @click="functionSendAction(formData.name, createModelSend())">
                Отправить
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
<template>
  <v-container>
    <v-row>
      <!--      <v-col class="v-col-12">-->
      <!--        <about-the-project-card/>-->
      <!--      </v-col>-->
      <v-col class="v-col-12">
        <project-contacts-card/>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Команда проекта
          </v-card-title>
          <v-card-text>
            <p>
              Привет, меня зовут Владимир, для друзей просто Володя. Я единственный, пока что, разработчик в этой
              системе и
              занимаюсь всем: и сайтом, и беком, и агентами, и серверами, и сетями, и маркетингом, и рекламой, и
              общением с клиентами,
              и отвечаю за всё, что тут происходит.
            </p>
            <br>
            <p>
              <b>Скажу пару слов о себе:</b> занимаюсь программированием уже c 12-го года, со времён школы. Олимпиады по
              информатике в школе и всё такое с этим связанное.
              Начинал с разработки игр на Java - пара лет, после чего познал сферу backend-разработки, по сей день
              сижу в этой сфере.
            </p>
            <br>
            <p>
              Ботами в телеграме(программными агентами - по-научному) я начал заниматься в 19-ом году, знакомый
              предложил
              написать бота, который позволял бы подписываться на
              него, что бы в системе <i>(скрыто название)</i> за ним у клиентов выполнялись те же действия. После этого
              я
              подался в сферу разработки телеграм ботов, выполняя простые заказы.
            </p>
            <br>
            <p>
              <b>Об идеи проекта:</b> идея проекта появилась в 20-ом году, когда после выполненного сложного
              заказа(без предоплаты конечно же), меня кинул заказчик и я остался с узконаправленным проектом, который
              никому не нужен, но он был идеален! Была в нём заложена текущая идея - управление программным агентом
              через сайт, после чего я вынашивал эту идею. Следующим шагом был собственный язык программирования,
              хотел сделать его <i>языком программирования ботов</i>, но подумав как всё это
              работает и кому оно нужно - отбросил идею в дальний угол. Ну и последним шагом было изучение реактивного
              стека программирования, тут у меня появилась идея распределённого управления программными агентами в
              единой
              системе, после чего взял за разработку... Прошёл год. Вот я пишу эти строки и скоро будет запуск проекта!
              =) <span style="color: #898989">Привет, мне в будущем!</span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectContactsCard from "@/components/ProjectContactsCard.vue";
import AboutTheProjectCard from "@/components/AboutTheProjectCard.vue";

export default {
  name: "ContactView",
  components: {AboutTheProjectCard, ProjectContactsCard}
}
</script>

<style scoped>

</style>
// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {createVuetify} from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
        icons: {
            defaultSet: 'mdi',
            aliases,
            sets: {
                mdi,
            },
        },
        theme: {
            defaultTheme: "light"
        },
        defaults: {
            VCard: {
                elevation: 4
            }
        }
    }
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
